<template>
  <div class="position-relative">
    <div v-if="canEdit" class="edit-icon-settings d-flex align-items-center">
      <feather-icon
        v-if="!isEditable"
        v-b-tooltip.hover.bottom
        icon="Edit2Icon"
        :title="$t('form-create-item.edit')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="openEdit"
      />
      <feather-icon
        v-if="isEditable"
        icon="XIcon"
        size="20"
        class="text-primary zindex-1 mr-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="closeEdit"
      />
      <feather-icon
        v-if="isEditable"
        v-b-tooltip.hover.bottom
        icon="SaveIcon"
        :title="$t('header.action.save-layout')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="handleEditOrder"
      />
    </div>
    <b-card>
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t(`collectives.${collectiveFilter}.title`) }}
          </h3>
        </div>
        <b-button
          v-if="canCreate || isStaff"
          class="float-right mr-1"
          variant="outline-primary"
          @click="openModal"
        >
          {{ $t("projects.create") }}
        </b-button>
      </div>
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <!-- <div v-else> -->
      <div
        v-else-if="communities && communities.length > 0 && canList"
        class="d-flex flex-wrap justify-content-around w-100"
      >
        <draggable
          v-if="isEditable"
          v-model="communities"
          :list="communities"
          :move="checkMove"
          class="w-100"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group class="d-flex flex-wrap justify-content-around w-100">
            <collective-card
              v-for="community in communities"
              v-show="collectiveFilter !== 'courses'"
              :key="community.key"
              class="x-scroll-element mx-1 mb-2"
              :collective="community"
              :is-draggable="isEditable"
            />
          </transition-group>
        </draggable>
        <collective-card
          v-for="community in communities"
          v-show="!isEditable && collectiveFilter !== 'courses'"
          :key="community.key"
          class="x-scroll-element mx-1 mb-2"
          :collective="community"
        />
      </div>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col v-if="placeholder" cols="12">
          <img :src="placeholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: collectiveFilter }) }}
          </p>
        </b-col>
      </b-row>

      <!-- <div v-if="isLoadingNextPage" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div> -->

      <b-button
        v-if="haveMore"
        class="center-x my-3"
        variant="outline-primary"
        @click="fetchData(lastLoadedPage + 1, false)"
      >
        {{ $t("action.load-more") }}
      </b-button>
      <!-- </div> -->

      <!-- Create subcommunity -->
      <!--collective-create-form 
        :collective-filter="collectiveFilter" 
      /-->
      <!-- Create subcommunity or section -->
      <b-modal
        :id="`modal-create-${collectiveFilter}`"
        centered
        hide-footer
        size="lg"
      >
        <template #modal-header>
          <language-selector-header 
            :title="$t('spaces.actions.create')" 
            @closeModal="closeModal" 
            @selectLanguage="(language)=>selectedLanguage = language" 
          />
        </template>
        <membership-event-create-modal
          :item-type="collectiveFilter"
          :selected="selectedLanguage"
          @close-modal="isModalVisible = false"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import ListContainer from '@core/constants/ListContainer';
import CollectiveFilter from '@/views/apps/collectives/constants/CollectiveFilter';
import CommunitiesPlaceholder from '@/assets/images/placeholders/light/communities.svg';
import { checkPermissions } from '@/@core/utils/roles-utils';
import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
//import { AppIDLUT } from '@copernicsw/community-common';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import draggable from 'vuedraggable';
import CollectiveCard from '../apps/collectives/components/CollectiveCard.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';

export default {
  name: 'CollectivesList',
  components: {
    CollectiveCard,
    MembershipEventCreateModal,
    draggable,
    LanguageSelectorHeader,
  },
  props: {
    collectiveFilter: {
      type: String,
      required: true,
      validator(collectiveFilter) {
        return Object.values(CollectiveFilter).includes(collectiveFilter);
      },
    },
  },
  data() {
    return {
      isContactModalVisible: false,
      isLoading: false,
      // isLoadingNextPage: false,
      perPage: 20,
      lastLoadedPage: 1,
      isEditable: false,
      dragging: false,
      actualItems: [],
      firstItems: [],
      isSending: false,
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    appId() {
      if (this.collectiveFilter === 'subcommunities') {
        return 67;
      }
      if (this.collectiveFilter === 'sections') {
        return 42;
      }
      return 18;
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === this.appId);
    },
    appTitle() {
      return translateTranslationTable(
        this.currentLocal,
        this.app?.customizationName,
      );
    },
    gridContainer() {
      return ListContainer.Grid;
    },
    placeholder() {
      return CommunitiesPlaceholder;
    },
    communities() {
      return this.$store.getters.communitiesOthers[this.collectiveFilter]
        ?.unpaginated || [];
    },
    haveMore() {
      return (
        this.$store.getters.communitiesOthers[this.collectiveFilter]
          ?.unpaginated.length
        < this.$store.getters.communitiesOthers[this.collectiveFilter]?.meta.total
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    canCreate() {
      let collectiveType = this.collectiveFilter;
      if (collectiveType === 'subcommunities') {
        return checkPermissions(
          'create',
          'subcommunity',
          this.loggedMemberRoles,
          this.collective,
        );
      }
      collectiveType = collectiveType.substring(0, collectiveType.length - 1);
      return checkPermissions(
        'create',
        collectiveType,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canList() {
      let collectiveType = this.collectiveFilter;
      if (collectiveType === 'subcommunities') {
        return (
          this.isStaff
          || checkPermissions(
            'index',
            'subcommunity',
            this.loggedMemberRoles,
            this.collective,
          )
        );
      }
      collectiveType = collectiveType.substring(0, collectiveType.length - 1);
      return (
        this.isStaff
          || checkPermissions(
            'index',
            collectiveType,
            this.loggedMemberRoles,
            this.collective,
          )
      );
    },
    canEdit() {
      let collectiveType = this.collectiveFilter;
      if (collectiveType === 'subcommunities') {
        return checkPermissions(
          'update',
          'subcommunity',
          this.loggedMemberRoles,
          this.collective,
        );
      }
      collectiveType = collectiveType.substring(0, collectiveType.length - 1);
      return checkPermissions(
        'update',
        collectiveType,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },

  created() {
    // this.isLoading = true;
    if (!this.communities.length) {
      this.fetchData();
    }
    // this.isLoading = false;
  },

  methods: {
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.communities];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [index, item] of this.actualItems.entries()) {
        item.order = index + 1;
      }
      const requestConfig = {
        communities: this.actualItems,
      };
      if (this.collectiveFilter === 'sections') requestConfig.mainTypeID = 6;
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: '/communities/simply/order',
          requestConfig,
        },
      });
      this.firstItems = [];
      this.firstItems = [...this.actualItems];
      this.isEditable = false;
      this.isSending = false;
    },
    openEdit() {
      this.firstItems = [];
      this.firstItems = [...this.communities];
      this.isEditable = true;
    },
    closeEdit() {
      this.$store.commit('SET_ITEMS', {
        type: 'communitiesOthers',
        storedKey: this.collectiveFilter,
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isEditable = false;
    },
    async fetchData(page = 1, force = true) {
      this.isLoading = true
      // if (this.page !== page) {
      //   this.isLoadingNextPage = true;
      // }
      this.page = page;
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
      };
      if (this.collectiveFilter === 'sections') {
        requestConfig.isSection = true;
      }
      if (this.collectiveFilter === 'courses') {
        requestConfig.isCourse = true;
      }
      await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: this.collectiveFilter,
        page,
        forceAPICall: force,
        perPage: this.perPage,
        requestConfig,
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
      // this.isLoadingNextPage = false;
    },
    openModal() {
      this.$bvModal.show(`modal-create-${this.collectiveFilter}`);
    },
    closeModal() {
      this.$bvModal.hide(`modal-create-${this.collectiveFilter}`);
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            this.appTitle
            || this.$t(`collectives.${this.collectiveFilter}.title`),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-icon-settings {
  position: absolute;
  right: 0px;
  top: -50px;
  cursor: pointer;
}
</style>
